<template>
  <div>
    <div class="flow-list-wrap" v-if="lists.length > 0">
      <div
        class="list-wrap"
        v-for="(item, index) in lists"
        :key="'list' + index"
      >
        <h2 class="title">{{ item.title }}</h2>
        <div class="flow-list">
          <div
            :class="item2.active ? 'list-item active' : 'list-item'"
            v-for="(item2, index2) in item.values"
            :key="'values' + index2"
            @click="selectItem({ index: index, index2: index2 })"
          >
            <div class="inner">
              <p class="num">{{ item2.num }}GB</p>
              <p>{{ item2.price }}元/月</p>
              <div class="is-top" v-if="item2.isTop">推荐</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      <svg-icon icon-class="no_data" />
      <p class="text">没有任何结果！</p>
    </div>
    <div class="total-wrap" v-if="isActive">
      <p>即日起至{{ validityDate }}</p>
      <p>月流量清零，本月剩余天数{{ days }}</p>
      <p class="total">
        合计 <span class="price">{{ total }}</span
        >元
      </p>
    </div>
    <div class="btn-con">
      <button type="button" class="btn" @click="$router.push('/pay')">
        选择
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'set-meal',
  data () {
    return {
      days: '20',
      validityDate: '2021年6月30日'
    }
  },
  created () {
    this.setList()
  },
  mounted () {
    document.title = this.$route.meta.title
  },
  computed: {
    ...mapState({
      lists: state => state.rechange.lists,
      total: state => state.rechange.total,
      isActive: state => state.rechange.isActive
    })
  },
  methods: {
    ...mapMutations({
      setList: 'rechange/setList',
      selectItem: 'rechange/selectItem'
    })
  }
}
</script>

<style lang="scss" scoped>
.flow-list-wrap {
  padding-top: 0.5rem;
  .list-wrap {
    margin-top: 1.75rem;
    .title {
      padding: 0 1.25rem;
      font-size: 1rem;
      line-height: 1.3125rem;
      margin-bottom: 0.5rem;
    }
  }
  .flow-list {
    padding: 0 0.6875rem;
    margin-top: -1.125rem;
    display: flex;
    flex-wrap: wrap;
    .list-item {
      width: 33.333%;
      margin-top: 1.125rem;
      padding: 0 0.5625rem;
      box-sizing: border-box;
      &.active {
        .inner {
          &::before {
            content: "";
          }
        }
      }
      .inner {
        background-color: #fff;
        border-radius: 5px;
        padding: 0.875rem 0 1.125rem;
        box-sizing: border-box;
        text-align: center;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #0176f7;
        position: relative;
        overflow: hidden;
        &::before {
          width: 100%;
          height: 100%;
          position: absolute;
          border: 2px solid #0176f7;
          left: 0;
          top: 0;
          box-sizing: border-box;
          border-radius: 5px;
        }
        .num {
          font-size: 1rem;
          line-height: 1.3125rem;
        }
        .is-top {
          height: 1.125rem;
          line-height: 1.125rem;
          position: absolute;
          left: -0.75rem;
          top: 0.2rem;
          font-size: 0.75rem;
          background-color: #ff6b00;
          width: 3.25rem;
          text-align: center;
          color: #fff;
          z-index: 10;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.total-wrap {
  margin-top: 2.5rem;
  padding: 0 1.125rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  text-align: right;
  color: #666;
  .total {
    margin-top: 0.3125rem;
    color: #333;
    .price {
      font-size: 1.125rem;
      color: #ff6b00;
      font-weight: bold;
    }
  }
}
.btn-con {
  margin-top: 2.5rem;
  padding: 0 1.25rem;
}
</style>
